import React, { useState } from "react";

export default function App() {
  const invoiceNumner = localStorage.getItem("invoice")
    ? localStorage.getItem("invoice")
    : 0;
  const [invoiceNumber, setInvoiceNumber] = useState(
    "INVOICE NUMBER : " + invoiceNumner
  );
  const [date, setDate] = useState("DATE : ");
  const [toAddress, setToAddress] = useState();
  const [igst, setIgst] = useState(false);

  const [rows, setRows] = useState([1]);

  const [sNo, setSNo] = useState([]);
  const [hsnCode, setHsnCode] = useState([]);
  const [description, setDescription] = useState([]);
  const [mrp, setMrp] = useState([]);
  const [qty, setQty] = useState([]);
  const [rate, setRate] = useState([]);
  const [total, setTotal] = useState([]);
  const [allTotal, setAllTotal] = useState(0);
  const [cgst, setCgst] = useState();
  const [sgst, setSgst] = useState();
  const [grandTotal, setGrandTotal] = useState();
  const [roundOff, setRoundOff] = useState();
  const [buttons, setButtons] = useState(false);

  const addRow = () => {
    setRows([...rows, 1]);
  };

  const deleteRow = async () => {
    const arr = [...rows];
    arr.splice(arr.length - 1, 1);
    setRows(arr);

    if (rows.length === qty.length) {
      const arrRate = [...rate];
      arrRate.splice(arrRate.length - 1, 1);
      setRate(arrRate);

      const arrQty = [...qty];
      arrQty.splice(arrQty.length - 1, 1);
      setQty(arrQty);

      const arrTotal = [...total];
      arrTotal.splice(arrTotal.length - 1, 1);
      setTotal(arrTotal);
    }
  };

  const handleUpdateTotals = () => {
    const uppdatedTotal = [...total];
    const updatedRate = [...rate];
    const updatedQty = [...qty];
    let tot = 0;
    for (let i = 0; i < qty.length; i++) {
      uppdatedTotal[i] = parseFloat(
        (parseInt(updatedQty[i]) * parseFloat(updatedRate[i])).toFixed(2)
      );
      tot = tot + uppdatedTotal[i];
    }
    setTotal(uppdatedTotal);
    setAllTotal(tot);

    const gst = igst ? tot * 0.05 : tot * 0.025;
    setCgst(parseFloat(gst.toFixed(2)));
    setSgst(parseFloat(gst.toFixed(2)));
    let grandTot = igst ? tot + gst : tot + gst + gst;
    const roundOff = parseFloat(grandTot - Math.floor(grandTot)).toFixed(2);
    setRoundOff(roundOff);
    setGrandTotal(Math.floor(grandTot));
  };

  const updateSNo = (index, value) => {
    const updatedValue = [...sNo];
    updatedValue[index] = value;
    setSNo(updatedValue);
    console.log(sNo);
  };

  const updateHsn = (index, value) => {
    const updatedValue = [...hsnCode];
    updatedValue[index] = value;
    setHsnCode(updatedValue);
    console.log(hsnCode);
  };

  const updateDescription = (index, value) => {
    const updatedValue = [...description];
    updatedValue[index] = value;
    setDescription(updatedValue);
    console.log(description);
  };

  const updateMrp = (index, value) => {
    const updatedValue = [...mrp];
    updatedValue[index] = value;
    setMrp(updatedValue);
    console.log(mrp);
  };

  const updateQty = (index, value) => {
    const updatedValue = [...qty];
    updatedValue[index] = value;
    setQty(updatedValue);
  };

  const updateRate = (index, value) => {
    const updatedValue = [...rate];
    updatedValue[index] = value;
    setRate(updatedValue);
  };

  const onOffButtons = () => {
    localStorage.setItem("invoice", invoiceNumber.split(":")[1].trim());
    setButtons(!buttons);
  };

  return (
    <>
      <table
        style={{
          borderTop: "1.5px solid #000",
          borderLeft: "1.5px solid #000",
          borderRight: "1.5px solid #000",
          width: "100%",
          height: "27px",
        }}
      >
        <tr
          style={{
            width: "1200px",
          }}
        >
          <div style={{ height: "27px", textAlign: "center" }}>
            <strong>TAX INVOICE (ORIGINAL)</strong>
          </div>
        </tr>
      </table>
      <table
        style={{
          borderTop: "1.5px solid #000",
          borderLeft: "1.5px solid #000",
          borderRight: "1.5px solid #000",
          width: "100%",
          height: "22px",
        }}
      >
        <tr>
          <td
            style={{
              borderRight: "1px solid #000",
              width: "50%",
              textAlign: "left",
            }}
          >
            <label>
              <strong>FROM</strong>
            </label>
            <p style={{ fontSize: "14px" }}>
              VVN SWEETS <br></br>
              NO: 279/5, NEW COLONY, KEELA ERAL,<br></br> ETTAYAPURAM (TK)
              TUTICORIN (DIST) <br></br>TAMIL NADU. <br></br>GST IN :
              33AGMPJ4730E1ZO <br></br>Email: wnsweets@gmail.com <br></br>MOB
              NO: 95000 43197, 63833 37206
            </p>
          </td>
          <td
            style={{
              width: "50%",
              textAlign: "left",
            }}
          >
            <div style={{ paddingBottom: "4px" }}>
              <strong>TO</strong>
            </div>
            <textarea
              rows="7"
              placeholder="type address here"
              value={toAddress}
              onChange={(e) => setToAddress(e.target.value)}
              style={{
                border: "none",
                width: "98%",
                height: "85%",
                resize: "none",
                fontSize: "14px",
              }}
            ></textarea>
          </td>
        </tr>
      </table>
      <table
        style={{
          borderTop: "1.5px solid #000",
          borderLeft: "1.5px solid #000",
          borderRight: "1.5px solid #000",
          width: "100%",
          height: "22px",
        }}
      >
        <tr>
          <td
            style={{
              borderRight: "1px solid #000",
              width: "50%",
              height: "22px",
            }}
          >
            <input
              value={invoiceNumber}
              onChange={(e) => setInvoiceNumber(e.target.value)}
              style={{ border: "none", height: "100%", width: "95%" }}
            ></input>
          </td>
          <td
            style={{
              width: "50%",
              height: "22px",
            }}
          >
            <input
              value={date}
              onChange={(e) => setDate(e.target.value)}
              style={{ border: "none", height: "100%", width: "95%" }}
            ></input>
          </td>
        </tr>
      </table>
      <table
        style={{
          borderTop: "1.5px solid #000",
          borderLeft: "1.5px solid #000",
          borderRight: "1.5px solid #000",
          width: "100%",
          height: "22px",
        }}
      >
        <thead>
          <tr
            style={{
              width: "1200px",
              height: "25px",
            }}
          >
            <th
              style={{
                borderRight: "1px solid #000",
                width: "6%",
                height: "25px",
                textAlign: "left",
              }}
            >
              S.No
            </th>
            <th
              style={{
                borderRight: "1px solid #000",
                width: "13%",
                height: "25px",
                textAlign: "left",
              }}
            >
              HSN Code
            </th>
            <th
              style={{
                borderRight: "1px solid #000",
                width: "26%",
                height: "25px",
                textAlign: "left",
              }}
            >
              Description of the goods
            </th>
            <th
              style={{
                borderRight: "1px solid #000",
                width: "12%",
                height: "25px",
                textAlign: "left",
              }}
            >
              MRP RATE
            </th>
            <th
              style={{
                borderRight: "1px solid #000",
                width: "11%",
                height: "25px",
                textAlign: "left",
              }}
            >
              QTY
            </th>
            <th
              style={{
                borderRight: "1px solid #000",
                width: "19%",
                height: "25px",
                textAlign: "left",
              }}
            >
              Rate(per item)
            </th>
            <th
              style={{
                width: "13%",
                height: "25px",
                textAlign: "left",
              }}
            >
              Total Value
            </th>
          </tr>
        </thead>
        <tbody>
          {rows.map((item, index) => (
            <tr
              key={index}
              style={{
                borderTop: "1px solid #000",
                width: "1200px",
                height: "22px",
              }}
            >
              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "50px",
                  height: "22px",
                }}
              >
                <input
                  style={{ border: "none", height: "93%", width: "93%" }}
                  onChange={(e) => updateSNo(index, e.target.value)}
                ></input>
              </td>
              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "100px",
                  height: "22px",
                }}
              >
                <input
                  style={{ border: "none", height: "93%", width: "93%" }}
                  onChange={(e) => updateHsn(index, e.target.value)}
                ></input>
              </td>
              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "100px",
                  height: "22px",
                }}
              >
                <input
                  style={{ border: "none", height: "93%", width: "93%" }}
                  onChange={(e) => updateDescription(index, e.target.value)}
                ></input>
              </td>
              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "100px",
                  height: "22px",
                }}
              >
                <input
                  style={{ border: "none", height: "93%", width: "93%" }}
                  onChange={(e) => updateMrp(index, e.target.value)}
                ></input>
              </td>
              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "100px",
                  height: "22px",
                }}
              >
                <input
                  style={{ border: "none", height: "93%", width: "93%" }}
                  onChange={(e) => updateQty(index, e.target.value)}
                ></input>
              </td>
              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "100px",
                  height: "22px",
                }}
              >
                <input
                  style={{ border: "none", height: "93%", width: "93%" }}
                  onChange={(e) => updateRate(index, e.target.value)}
                ></input>
              </td>
              <td
                style={{
                  borderTop: "1px solid #000",

                  height: "22px",
                }}
              >
                {" "}
                {total[index]}
              </td>
            </tr>
          ))}
          <tr id="total">
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",

                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            >
              <div style={{ fontSize: "12px" }}>
                <strong>AMOUNT IN WORDS</strong>
              </div>
            </td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            >
              <strong>Total</strong>
            </td>
            <td
              style={{
                borderTop: "1px solid #000",

                width: "100px",
                height: "22px",
              }}
            >
              {allTotal}
            </td>
          </tr>
          <tr id="cgst">
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            >
              <textarea
                rows={3}
                style={{
                  border: "none",
                  resize: "none",
                  width: "97%",
                  height: "90%",
                  fontSize: "12px",
                  padding: "1px",
                }}
                placeholder="type amounts in CAPITAL letters"
              ></textarea>
            </td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            >
              {" "}
              {igst ? <strong>IGST @ 5%</strong> : <strong>CGST @ 2.5%</strong>}
            </td>
            <td
              style={{
                borderTop: "1px solid #000",

                width: "100px",
                height: "22px",
              }}
            >
              {cgst}
            </td>
          </tr>
          {!igst && (
            <tr id="sgst">
              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "100px",
                  height: "22px",
                }}
              ></td>
              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "100px",
                  height: "22px",
                }}
              ></td>
              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "100px",
                  height: "22px",
                }}
              ></td>
              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "100px",
                  height: "22px",
                }}
              ></td>
              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "100px",
                  height: "22px",
                }}
              ></td>

              <td
                style={{
                  borderTop: "1px solid #000",
                  borderRight: "1px solid #000",
                  width: "100px",
                  height: "22px",
                }}
              >
                <strong>SGST @ 2.5%</strong>
              </td>

              <td
                style={{
                  borderTop: "1px solid #000",

                  width: "100px",
                  height: "22px",
                }}
              >
                {sgst}
              </td>
            </tr>
          )}
          <tr id="roundOff">
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            >
              <strong>ROUND OFF(-)</strong>
            </td>
            <td
              style={{
                borderTop: "1px solid #000",

                width: "100px",
                height: "22px",
              }}
            >
              {roundOff}
            </td>
          </tr>
          <tr id="grandTotal">
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",

                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
            ></td>
            <td
              style={{
                borderTop: "1px solid #000",
                borderRight: "1px solid #000",
                width: "100px",
                height: "22px",
              }}
              onClick={onOffButtons}
            >
              <strong>GRAND TOTAL</strong>
            </td>
            <td
              style={{
                borderTop: "1px solid #000",

                width: "100px",
                height: "22px",
              }}
            >
              <strong>{grandTotal}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <table
        style={{
          borderTop: "1.5px solid #000",
          borderLeft: "1.5px solid #000",
          borderRight: "1.5px solid #000",
          width: "100%",
          height: "22px",
        }}
      >
        <tr>
          <td
            style={{
              borderRight: "1px solid #000",
              width: "50%",
              height: "22px",
              textAlign: "left",
              padding: "6px",
            }}
          >
            BANK ACCOUNT NAME : VVN SWEETS <br></br>BANK ACCOUNT NUMBER :
            404150050800117 <br></br>BANK DETAILS : TMB, KEELAERAL <br></br>
            IFSC : TMBL0000404
          </td>
          <td
            style={{
              width: "50%",
              height: "22px",
              textAlign: "left",
              padding: "6px",
            }}
          ></td>
        </tr>
      </table>
      <table
        style={{
          borderTop: "1.5px solid #000",
          borderLeft: "1.5px solid #000",
          borderRight: "1.5px solid #000",
          borderBottom: "1.5px solid #000",
          width: "100%",
          height: "22px",
        }}
      >
        <tr
          style={{
            height: "22px",
          }}
        >
          <td
            style={{
              borderRight: "1px solid #000",
              width: "50%",

              textAlign: "left",
              padding: "3px",
            }}
          >
            CUSTOMER'S SIGNATURE
            <br></br>
            {""} <br></br>
          </td>
          <td
            style={{
              width: "50%",

              textAlign: "left",
              padding: "3px",
            }}
          >
            for VVN SWEETS
          </td>
        </tr>
      </table>
      <div style={{ padding: "20px" }}>
        <button onClick={addRow} hidden={buttons} style={{ margin: "15px" }}>
          Add Row
        </button>
        <button onClick={deleteRow} hidden={buttons} style={{ margin: "15px" }}>
          Delete Row
        </button>
        <button
          onClick={handleUpdateTotals}
          hidden={buttons}
          style={{ margin: "15px" }}
        >
          Update Totals
        </button>
        <button
          onClick={(e) => setIgst(!igst)}
          hidden={buttons}
          style={{ margin: "15px" }}
        >
          {igst ? "SGST" : "IGST"}
        </button>
        <label hidden={buttons}>
          note : to remove or add these buttons, clik on field "GRAND TOTAL"
        </label>
      </div>
    </>
  );
}
